import { FC } from 'react';

import { FiLock, FiMail } from 'react-icons/fi';
import { useForm } from 'react-hook-form';

import Link from 'next/link';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { routes } from '~/shared/constants/routes';

import { useAuth } from '~/modules/auth/hooks/useAuth';

import { FormGroup } from '~/shared/components/Form/FormGroup';
import { ControlledInput } from '~/shared/components/Form/ControlledInput';
import { Button } from '~/shared/components/Button';
import { AuthTitle } from '~/shared/components/AuthLayout/AuthTitle';
import { AuthForm } from '~/shared/components/AuthLayout/AuthForm';

import { ISignInCredentials } from '../../interfaces/ISignInCredentials';

import { FormFooter } from './styles';

const schemaValidation = yup
  .object({
    email: yup
      .string()
      .email('O formato do e-mail está incorreto')
      .required('E-mail é obrigatório'),
    password: yup.string().required('Senha é obrigatório'),
  })
  .required();

export const SignIn: FC = () => {
  const {
    control,
    formState: { isSubmitting, errors },
    handleSubmit,
  } = useForm<ISignInCredentials>({
    defaultValues: { email: '', password: '' },
    resolver: yupResolver(schemaValidation),
  });

  const { signIn } = useAuth();

  const handleSignIn = handleSubmit(signIn);

  return (
    <>
      <AuthTitle title="Faça o seu login na platafoma" />

      <AuthForm onSubmit={handleSignIn}>
        <FormGroup>
          <ControlledInput
            control={control}
            name="email"
            icon={FiMail}
            placeholder="Digite seu e-mail"
            inputMode="email"
            autoComplete="email"
            size="lg"
            error={errors.email}
          />
        </FormGroup>

        <FormGroup>
          <ControlledInput
            control={control}
            name="password"
            icon={FiLock}
            placeholder="Digite sua senha"
            type="password"
            size="lg"
            autoComplete="current-password"
            error={errors.password}
          />
        </FormGroup>

        <Button type="submit" isLoading={isSubmitting} size="lg" mt="md">
          Entrar
        </Button>

        <FormFooter>
          <span>
            Esqueceu sua senha?{' '}
            <Link href={routes.AUTH.PASSWORD_RECOVERY}>Recuperar</Link>
          </span>
        </FormFooter>
      </AuthForm>
    </>
  );
};
