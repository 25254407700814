import styled from 'styled-components';

export const FormFooter = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  gap: ${({ theme }) => theme.spacing.xs};

  margin-top: ${({ theme }) => theme.spacing.md};

  span {
    text-align: center;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.onSurface};
    line-height: 24px;

    a {
      color: ${({ theme }) => theme.colors.primary};
      white-space: nowrap;
      font-weight: 700;

      transition: filter 0.3s ease-in-out;

      &:hover {
        filter: brightness(0.75);
      }
    }
  }
`;
