import { FC } from 'react';

import Image from 'next/image';

import logoImg from '~/shared/assets/images/quero-jobs-logo.png';

import { Container } from './styles';

interface IAuthTitleProps {
  title: string;
}

export const AuthTitle: FC<IAuthTitleProps> = ({ title }) => {
  return (
    <Container>
      <Image src={logoImg} height={60} width={300} alt="PagLee" />

      <h1>{title}</h1>
    </Container>
  );
};
