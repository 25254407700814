import styled from 'styled-components';

export const Container = styled.aside`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  max-width: 480px;
  width: 100%;

  gap: ${({ theme }) => theme.spacing.lg};

  img {
    object-position: left;
    object-fit: contain;
  }

  h1 {
    color: ${({ theme }) => theme.colors.onBackground};
    font-size: 48px;
    line-height: 64px;
    max-width: 400px;
    font-weight: bold;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    align-items: center;

    img {
      object-position: center;
    }

    h1 {
      text-align: center;
      font-size: 32px;
      max-width: 70%;
      line-height: 48px;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding-top: ${({ theme }) => theme.spacing.md};

    h1 {
      font-size: 24px;
      line-height: 42px;
    }
  }
`;
