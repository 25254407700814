import styled from 'styled-components';

export const AuthForm = styled.form`
  max-width: 480px;
  width: 100%;
  overflow: hidden;

  background: ${({ theme }) => theme.colors.surface};
  padding: ${({ theme }) => theme.spacing.xxlg};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  border: ${({ theme }) => `1px solid ${theme.colors.gray[100]}`};

  #button {
    width: 100%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: ${({ theme }) => theme.spacing.xlg};
  }

  @media (max-width: 500px) {
    padding: ${({ theme }) => theme.spacing.lg};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding: ${({ theme }) => theme.spacing.md};
  }
`;
